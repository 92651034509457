import { ReactNode, useEffect, useState } from "react";
import { EVOLUTIVO_WS_URL } from "./config";
import EvoLogin from "./EvoLogin";
import EvoLoader from "./EvoLoader";
import { ToastContainer, toast } from 'react-toastify';
import { DataProvider } from "@plasmicapp/host";
//@ts-ignore
import * as cbconn from 'corebos-ws-lib/WSClientm';
import 'react-toastify/dist/ReactToastify.min.css';
import EvoHeader from "./EvoHeader";



const SAVED_USER_ITEM: string = 'currentUser';

export interface LoginData {
    sessionName: string;
    userId: string;
}
export interface User {
    logindata?: LoginData;
    contact?: any;
    id: string;
    first_name?: string;
    last_name?: string;
    gender?: string;
    email1?: string;
    date_format?: string;
    currency_symbol_placement?: string;
    currency_grouping_separator?: string;
    currency_grouping_pattern?: string;
    currency_decimal_separator?: string;
    is_admin?: boolean;
    language?: string;
    profileid?: any;
    roleid?: string;
    rolename?: string;
}

export interface BackroundStyle {
    backgroundImage: string;
    backgroundSize: string;
    backgroundPosition: string;
}

const BaseLayout = ({children, className, evolutivoWsUrl = EVOLUTIVO_WS_URL, loginPath, containerBgStyle, appContainerBgStyle = false, moduleName, showHeader = true, showBackButton } : {children?: ReactNode, className: string, evolutivoWsUrl?: string, loginPath?: string, containerBgStyle?: BackroundStyle, appContainerBgStyle?: boolean, moduleName?: string, showHeader: boolean, showBackButton?: boolean}) => {

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLogin, setIsLogin] = useState<boolean>(false);
    const [ currentUser, setCurrentUser ] = useState<User | null>(null);
    const [describeData, setDescribeData] = useState<any>({});



    useEffect(() => {
        const getDescribeData = async (resource: string) => {
            const result: any = await cbconn.doDescribe(resource).catch((err: any) => {
                return null;
            });
            if(result){
                const data: any = {};
                data[resource] = result;
                setDescribeData(data);
                const windowObj: any = window;
                if (windowObj.coreBOS === undefined) {
                    windowObj.coreBOS = {};
                }
                windowObj.coreBOS.Describe = windowObj.coreBOS?.Describe ?? {};
                windowObj.coreBOS.Describe[resource] = result;
            }
        }
        const authenticate = async () => {
            setIsLoading(true);
            if (!currentUser?.id){
                let savedUser: any = localStorage.getItem(SAVED_USER_ITEM) ?? null;
                savedUser = savedUser ? JSON.parse(savedUser) : null;
                if (!savedUser?.id) {
                    setIsAuthenticated(false);
                } else {
                    cbconn.setSession(savedUser?.logindata);
                    moduleName && await getDescribeData(moduleName);
                    setCurrentUser(savedUser);
                    setIsAuthenticated(true);
                }
            } else {
                cbconn.setSession(currentUser?.logindata);
                moduleName && await getDescribeData(moduleName);
                setIsAuthenticated(true);
            }
            setIsLoading(false);
            setIsLogin(false);
        }

        if(!isAuthenticated && typeof window !== "undefined"){
            const windowObj: any = window;
            if (windowObj.coreBOS===undefined) {
                windowObj.coreBOS = {};
            }
            window.addEventListener('coreBOSSessionExpiredEvent', function (e) {
                logout()
            }, false);
            
            window.addEventListener('coreBOSAuthorizationRequiredEvent', function (e) {
                logout()
            }, false);

            cbconn.setURL(evolutivoWsUrl, null);
            authenticate();
        }    
    }, [currentUser?.id, currentUser?.logindata, evolutivoWsUrl, isAuthenticated, loginPath, moduleName]);

    const logout = () => {
        localStorage.removeItem(SAVED_USER_ITEM);
        setCurrentUser(null);
        setIsAuthenticated(false);
        window.location.href = '/';
    }

    const login = async (values: any) => {
        const {username, password} = values;
        setIsLogin(true);
        const res: any = await cbconn.doLogin(username, password, false).catch((err: any) => null);
        if(res !== false && cbconn.hasError(res) === false){
            const coreboslogindata = res['result'];
            const logindata: any = { sessionName: coreboslogindata?.sessionName, userId: coreboslogindata?.userId };
            cbconn.setSession(logindata);
            const coreBOSUser: any = await cbconn.doInvoke('getPortalUserInfo');
            const contactdata = await cbconn.doQuery(`SELECT * FROM Contacts WHERE assigned_user_id=${coreBOSUser?.id} LIMIT 1`);
            const contact = Array.isArray(contactdata) && contactdata[0] ? contactdata[0] : {};
            const user: any = {contact: contact, ...coreBOSUser, ...{logindata}};
            localStorage.setItem(SAVED_USER_ITEM, JSON.stringify(user));
            setCurrentUser(user);
            setIsAuthenticated(true);
            setIsLoading(false);
            window.location.reload();
        } else {
            openNotification('error', 'Incorrect username or password');
        }
        setIsLogin(false);
    }

    const openNotification = (type: 'success' | 'info' | 'warn' |  'error', message: string, position?: 'top-right' | 'top-center' | 'top-left' |  'bottom-right' | 'bottom-center' | 'bottom-left') => {
        toast[type || 'success'](message, {position: position ?? 'bottom-center'});
    };

    if(isLoading) {
        return <EvoLoader className={className} />
    }

    if(!isLoading && !isAuthenticated) {
        return (
            <div className={className}>
                <EvoLogin 
                    onFormSubmit={(values: any) => {
                        login(values)
                    }}
                    submitBtnLoading={isLogin}
                    welcomeImgStyles={containerBgStyle}
                />
                <ToastContainer />
            </div>
        )
    }

    return (
        <div className={className} style={ appContainerBgStyle ? {...containerBgStyle} : {} }> 
            <DataProvider name="evoData" data={{evoUer: currentUser, logout: logout, evolutivoWsUrl: evolutivoWsUrl, moduleName: moduleName, describe: describeData }}>
                {showHeader && <EvoHeader user={currentUser?.contact} logout={logout} showBackButton={showBackButton} />}
                <div style={{marginTop: '64px'}}> {children} </div>
            </DataProvider>
            <ToastContainer />
        </div>
    )

}

export default BaseLayout;