import * as React from "react";
import {
  PlasmicTextInput,
  DefaultTextInputProps
} from "./plasmic/evo_components/PlasmicTextInput";

import { TextInputRef } from "@plasmicapp/react-web";
import { useEffect, useState } from "react";

export interface TextInputProps extends DefaultTextInputProps {
  // Feel free to add any additional props that this component should receive
  label?: string;
  uitype?: string;
  isEditable?: boolean;
}

function TextInput_(props: TextInputProps, ref: TextInputRef) {

  const [inputValue, setInputValue] = useState<any>('');
  const {value, label, isEditable, onChange, placeholder} = props;
  const { plasmicProps } = PlasmicTextInput.useBehavior<TextInputProps>(props, ref);
  plasmicProps.variants.isDisabled = isEditable;

  useEffect(() => {
    setInputValue(value);
  }, [value])

  useEffect(() => {
    onChange && onChange(inputValue);
  }, [inputValue, onChange])
  

  return (
    <PlasmicTextInput 
      {...plasmicProps}
      input={{
        value: inputValue,
        onChange: (e: any) => setInputValue(e.target.value),
        placeholder: placeholder ?? label,
      }}
    />
  )

}

const TextInput = React.forwardRef(TextInput_);

export default Object.assign(
  TextInput,

  {
    __plumeType: "text-input"
  }
);
