import * as React from "react";
import {
  PlasmicListView,
  DefaultListViewProps
} from "./plasmic/evo_components/PlasmicListView";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useEffect, useState } from "react";
import dataProvider from 'react-admin-corebos/corebosServerProvider';
import InfiniteScroll from "react-infinite-scroll-component";


export interface ListViewProps extends DefaultListViewProps { }

function ListView_(props: ListViewProps, ref: HTMLElementRefOf<"div">) {

  const { resource, paginatingStyle, perPage } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [listData, setListData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');


  useEffect(() => {
    if (resource) {
      let currentFilter: any = {};
      if(searchValue){
        const labelFields: string = getSearchField(resource);
        if(labelFields){
          currentFilter[`cblistsearch_${resource}`] = searchValue;
        }
      }
      loadItems(currentPage, perPage ?? 10, resource, currentFilter, paginatingStyle);
    }
  }, [searchValue, currentPage, perPage, resource, paginatingStyle]);

  const loadItems = (pageNumber: number, pageSize: number, module: string = '', filters: any = {}, paginationStyle = '') => {
    setIsLoading(true);
    dataProvider.getList(module, {pagination: {page: pageNumber, perPage: pageSize}, filter: filters, sort: {}}).then((res: {data: any[], total: number}) => {
      const { data, total } = res;
      setTotal(total);
      if(paginationStyle === 'onscroll'){
        setListData((prevData) => {
          const newData = [...prevData, ...data];
          return newData;
        });
      } else {
        setListData(data);
      }
    }).catch((err: any) => {
      console.log(err)
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const getSearchField = (moduleName: string = ''): string => {
    const window_: any = window;
    const moduleDescribe: any = window_?.coreBOS?.Describe[moduleName] ?? {};
    const labelFields: string = moduleDescribe?.labelFields ?? '';
    return labelFields?.split(',')[0] ?? ''
  }

  const onScroll = (page: number) => {
    setCurrentPage(page + 1);
  }

  const refresh = () => {
    setTotal(0);
    setCurrentPage(1);
  }

  if(paginatingStyle === 'onscroll'){
    return (
      <InfiniteScroll
        dataLength={listData.length}
        next={() => onScroll(currentPage)}
        hasMore={listData.length !== total}
        loader={ isLoading ? <h4 style={{textAlign: 'center'}}>Loading...</h4> : <></>}
        pullDownToRefresh={true}
        refreshFunction={refresh}
      >
        <PlasmicListView 
          listViewContainer={{ ref }} 
          {...props} 
          isLoading={isLoading} 
          data={listData}
          pagination={{
            current: currentPage,
            total: total,
            onChange(page) {
              setCurrentPage(page);
            },
          }}
          listSearchTextInput={{
            value: '',
            onChange: (value: any) => {
              if(typeof value === 'string'){
                setSearchValue(value)
              }
            }
          }}
        />
      </InfiniteScroll>
    );
  }

  return (
    <PlasmicListView 
        listViewContainer={{ ref }} 
        {...props} 
        isLoading={isLoading} 
        data={listData}
        pagination={{
          current: currentPage,
          total: total,
          onChange(page) {
            setCurrentPage(page);
          },
        }}
        listSearchTextInput={{
          value: '',
          onChange: (value: any) => {
            if(typeof value === 'string'){
              setSearchValue(value)
            }
          }
        }}
      />
  );

}

const ListView = React.forwardRef(ListView_);
export default ListView;
