import { PlasmicCanvasHost, registerComponent } from '@plasmicapp/react-web/lib/host'
import BaseLayout from '../components/BaseLayout';
import EvoForm from '../components/EvoForm';
import EvoFormField from '../components/EvoFormField';
import TextInput from '../components/TextInput';
import EvoDetailsView from '../components/EvoDetailsView';


registerComponent(BaseLayout, {
    name: 'BaseLayout',
    importPath: "./src/components/BaseLayout",
    isDefaultExport: true,
    providesData: true,
    props: {
      children: {
        type: "slot",
      },
      appName: {
        type: "string",
        defaultValue: "Evo UI",
      },
      showHeader: {
        type: "boolean",
        defaultValue: true,
      },
      showBackButton: {
        type: "boolean",
        defaultValue: true
      },
      evolutivoWsUrl: {
        type: "string",
        defaultValue: "https://rubber-road.evolutivo.it/",
        description: "Change this to your API url you want to use"
      },
      loginPath: {
        type: "string",
        description: "Leave this empty to use default Evolutivo Login component"
      },
      moduleName: {
        type: "string",
        defaultValue: ''
      },
      containerBgStyle: {
        type: "object",
        defaultValue: {
          "backgroundImage": "url(https://app-rubber.evolutivo.it/rubberpwaBg.png)",
          "backgroundSize": "cover",
          "backgroundPosition": "center"
        },
        description: "This will apply in this page/component as well as login component"
      },
      applyContainerBgStyle: {
        type: "boolean",
        defaultValue: false,
        description: "Set this to true if you want to apply page/component background specified in `containerBgStyle`"
      },
    }
});

registerComponent(EvoForm, {
  name: 'EvoForm',
  displayName: "Evolutivo Form",
  importPath: "./src/components/EvoForm",
  isDefaultExport: true,
  providesData: true,
  props: {
    children: {
      type: "slot",
      hidePlaceholder: true,
    },
    moduleName: {
      type: "string",
      defaultValue: ''
    },
    moduleFields: {
      type: "array",
      defaultValue: [],
    },
    defaultValues: {
      type: "object",
      defaultValue: {}
    }
  }
});

registerComponent(EvoFormField, {
  name: 'EvoFormField',
  displayName: "Evolutivo Form Field",
  importPath: "./src/components/EvoFormField",
  isDefaultExport: true,
  props: {
    children: {
      type: "slot",
      hidePlaceholder: true,
    },
    name: {
      type: "string",
      defaultValue: "",
    }
  }
});
registerComponent(TextInput, {
  name: 'EvoTextinput',
  displayName: "Evolutivo Text Input",
  importPath: "./src/components/EvoInput",
  isDefaultExport: false,
  props: {
    value: {
      type: "string",
      defaultValue: "",
    },
    label: {
      type: "string",
      defaultValue: ""
    },
    uitype: {
      type: "string",
    },
    required: {
      type: "boolean",
      defaultValue: false
    },
    isEditable: {
      type: "boolean",
      defaultValue: true
    },
    type: {
      type: "choice",
      options: [
        {label: "Text", value: "text"},
        {label: "Password", value: "password"},
        {label: "Hidden", value: "hidden"},
        {label: "Number", value: "number"},
        {label: "Date", value: "date"},
        {label: "Datetime-local", value: "datetime-local"},
        {label: "Time", value: "time"},
        {label: "Email", value: "email"},
        {label: "Telephone", value: "tel"}
      ],
      defaultValue: "text"
    }
  }
});

registerComponent(EvoDetailsView, {
  name: 'EvoDetailsView',
  displayName: "Evo Details View",
  importPath: "./src/components/EvoDetailsView",
  isDefaultExport: true,
  providesData: true,
  props: {
    children: {
      type: "slot",
      hidePlaceholder: true,
    },
    recordId: {
      type: "string",
      defaultValue: "",
    },
    moduleName: {
      type: "string",
      defaultValue: ''
    },
  }
});

const PlasmicHost = () => {
  return (
    <PlasmicCanvasHost />
  )
}

export default PlasmicHost;
